<template>
  <div>
    <!-- <RegistrationHeader icon="mdi-currency-usd" title="Payment Method"></RegistrationHeader> -->

  <v-card v-if="this.$store.state.registration.app.Status===completedStatus || this.$store.state.registration.app.Status===canceledStatus ">    
   <PageHeader title="Request Complete or Canceled - Payment Method Not Available" :subtitle="ymm"></PageHeader>
  </v-card>

 <v-card v-if="this.$store.state.registration.app.Status!=completedStatus && this.$store.state.registration.app.Status!=canceledStatus">    
   <PageHeader title="Payment Method" :subtitle="ymm"></PageHeader>


    <v-card>
      <v-card color="white" outlined class="mb-6">
        <v-card-title class="mainBlack--text">
          <div class="d-flex flex flex-column flex-sm-row justify-sm-space-between">
            <div class="gotham-font">Total Amount Due</div>
            <h1 class="mt-4 mt-sm-0">{{ totalAmountDue | currency }}</h1>
          </div>
        </v-card-title>
        <v-card-text class="mainBlack--text gotham-font">
          <v-btn small to="cost-breakdown" class="grey lighten-3 mainBlack--text">Click to see how we got this amount</v-btn>
        </v-card-text>
      </v-card>

      <v-form ref="paymentTypeForm" v-model="paymentTypeForm">
        <v-card flat>
          <v-card-text>
            <div class="text-h5 mt-0">Select Your Method of Payment </div>
            <div class="text-subtitle-1 ml-4">Payment instructions will appear after selection <i>(Note: Please use recommended browser Chrome on a desktop/laptop)</i></div>
                        <!-- <v-radio-group :change="savePaymentInfo()" v-model="paymentType" :rules="rules.paymentType"> -->
            <v-radio-group v-model="paymentType" :rules="rules.paymentType">
              <!-- <p>{{ paymentType || 'null' }}</p> -->
              <v-radio
                color="red"
                checked
                :value="payNow"
                class="large-text pa-4"
                style="border: 15px solid green; border-radius: 15px"
                :class="paymentType === payNow ? 'success--text' : ''"
              >
                <span slot="label" :class="paymentType === payNow ? 'success--text' : ''">Pay Now</span>
              </v-radio>
              <v-radio
                color="success"
                :value="wireType"
                class="large-text pa-4"
                style="border: 1px solid green; border-radius: 5px"
                :class="paymentType === wireType ? 'success--text' : ''"
              >
                <span slot="label" :class="paymentType === wireType ? 'success--text' : ''">ACH/Wire Transfer</span>
              </v-radio>
              <v-radio
                color="success"
                :value="checkType"
                class="large-text pa-4"
                style="border: 1px solid green; border-radius: 5px"
                :class="paymentType === checkType ? 'success--text' : ''"
              >
                <span slot="label" :class="paymentType === checkType ? 'success--text' : ''">Money Order or Cashier's Check</span>
              </v-radio>
            </v-radio-group>
          </v-card-text>

          

          <v-card-text v-if="paymentType">
            <div class="text-h5">Payment Instructions</div>
            <v-card class="success mt-4">
              <v-card-text class="text-body-1" v-if="paymentType!=payNow">
                * Be sure to add <b>"{{ vinAndLastName }}"</b> to your payment
              </v-card-text>
            </v-card>

            <div class="mt-6 mb-8 grey--text" v-show="instructionsToShow > -1">
              <div v-show="instructionsToShow === 0">
                <!-- <div class="text-body-1 my-4">Information for Completing Your Wire Transfer</div> -->
                <table class="table">
                  <tr v-for="(help, x) in payNowInstructions" :key="x">
                    <td>{{ help.item }}</td>
                    <td>{{ help.value }}</td>
                  </tr>
                </table>
              </div>

              <div v-show="instructionsToShow === 1">
                <!-- <div class="text-body-1 my-4">Information for Completing Your Money Order or Cashiers Check</div> -->
                  <table class="table">
                  <tr v-for="(help, x) in wireInstructions" :key="x">
                    <td>{{ help.item }}</td>
                    <td>{{ help.value }}</td>
                  </tr>
                </table>
              </div>

               <div v-show="instructionsToShow === 2">
                <!-- <div class="text-body-1 my-4">Information for Completing Your Money Order or Cashiers Check</div> -->
                <table class="table">
                  <tr v-for="(help, x) in moneyOrderInstructions" :key="x">
                    <td style="white-space: nowrap">{{ help.item }}</td>
                    <td>{{ help.value }}</td>
                  </tr>
                </table>
              </div>

              <div class="mt-4">
                * Please contact us at <a target="_blank" href="mailto:register@thetitlegirl.com">register@thetitlegirl.com</a> for more
                info.
              </div>
              <div class="mt-4">
                * These funds will be used to remit taxes to the county, pay registration fees, and The Title Girl services.
              </div>
            </div>
          </v-card-text>

            
          <v-card-subtitle  v-if="paymentType=== payNow ">Enter the name and address as it appears on your bank account </v-card-subtitle>
            <v-card-text v-if="paymentType=== payNow ">
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    autofocus
                    name="fullName"
                    label="Full Name"
                    maxlength="50"
                    class=""
                    v-model="bill.fullName"
                    :rules="rules.name"
                    :counter="50"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    type="tel"
                    name="phone number"
                    label="Phone number"
                    class=""
                    v-model="bill.phoneNumber"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="address-line-1"
                    label="Address Line 1"
                    class=""
                    v-model="bill.addressOne"
                    :rules="rules.address"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    name="address-line-2"
                    label="Address Line 2"
                    class=""
                    v-model="bill.addressTwo"
                    :rules="bill.addressTwo ? rules.address : []"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="city"
                    label="City"
                    class=""
                    v-model="bill.city"
                    :rules="rules.city"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="state"
                    label="State"
                    class="state"
                    v-model="bill.state"
                    @keyup="uppercase()"
                    :rules="rules.state"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    dense
                    outlined
                    required
                    type="tel"
                    name="zipcode"
                    label="Zip Code"
                    maxlength="5"
                    class=""
                    v-model="bill.zip"
                    :rules="rules.zip"
                    :disabled="requestPending || appSubmitted"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

           <v-card-subtitle  v-if="paymentType=== payNow ">Enter your bank account details</v-card-subtitle>
               <v-card-text v-if="paymentType=== payNow ">
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="bankName"
                    label="Bank Name"
                    maxlength="200"
                    class=""
                    v-model="bill.bankName"
                    :rules="rules.bankName"
                    :counter="200"
                  ></v-text-field>
                </v-col>
             </v-row>
          
          <v-row>

              <v-col cols="12" xs="12" sm="6">
          
           <v-card-subtitle  v-if="paymentType=== payNow">Account Type</v-card-subtitle>
             <v-radio-group required
        v-model="bill.accountType"
        row
      >
        <v-radio
          label="Checking"
          value="C"
        ></v-radio>
        <v-radio
          label="Savings"
          value="S"
        ></v-radio>
      </v-radio-group>

                </v-col>
                
              </v-row>
              
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="routingNumber"
                    label="Routing number"
                    class=""
                    v-model="bill.routingNumber"
                    :type="routingOneViewable ? 'text' : 'text'"
                    :rules="rules.routingNumber"
                    @click:append="() => (routingOneViewable = !routingOneViewable)"
                    autocomplete="nope"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="accountNumber"
                    label="Account Number"
                    class=""
                    v-model="bill.accountNumber"
                    :type="accountOneViewable ? 'text' : 'text'"
                    :rules="rules.accountNumber"
                    @click:append="() => (accountOneViewable = !accountOneViewable)"
                    autocomplete="nope"
                  ></v-text-field>
                </v-col>
              </v-row>
            

              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="routing number"
                    label="Confirm Routing number"
                    class=""
                    v-model="bill.confirmRoutingNumber"
                    :rules="[confirmRoutingNumberRequired, checkRoutingNumberMatch]"
                    :type="routingTwoViewable ? 'text' : 'text'"
                    @click:append="() => (routingTwoViewable = !routingTwoViewable)"
                    autocomplete="nope"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    required
                    name="account number"
                    label="Confirm Account Number"
                    class=""
                    v-model="bill.confirmAccountNumber"
                    :rules="[confirmAccountNumberRequired, checkAccountNumberMatch]"
                    :type="accountTwoViewable ? 'text' : 'text'"
                    @click:append="() => (accountTwoViewable = !accountTwoViewable)"
                    autocomplete="nope"
                  ></v-text-field>
                </v-col>
              </v-row>
     

            </v-card-text>

        <v-card-actions v-if="paymentType=== payNow">
        <section class="d-flex pa-3 mt-8" style="width: 50%">
          <BackButton text="cancel"></BackButton> 
          
      <!-- <v-btn v-if="!userIsAuthed && notSignInRoute" :to="{ name: 'SignIn' }" text>Sign In</v-btn>
      <v-btn v-if="userIsAuthed && $route.name !== 'app.dashboard'" :to="{ name: 'app.dashboard' }" text color="mainRed">
        <v-icon>mdi-home</v-icon>
      </v-btn> -->

          <v-btn
                      large
                      type="submit"
                      class="success"
                      block
                      :disabled="requestPending"
                      :loading="requestPending"
                      @click.native.prevent="submitACHPayment()"
                    >            Submit ACH payment
          </v-btn> 
        </section>
      </v-card-actions>

      

          <!-- <v-card-actions>
          <section class="d-flex flex pa-3">
            <BackButton></BackButton>
            <v-btn
              large
              class="ml-auto"
              type="submit"
              color="success"
              :loading="requestPending"
              :disabled="!paymentTypeForm"
              @click.native.prevent="savePaymentInfo()"
            >
              Save
            </v-btn>
          </section>
        </v-card-actions> -->
        </v-card>
    </v-form>
    </v-card>
     </v-card>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapState, mapGetters } from 'vuex';
import { statesAndAbbreviations } from '../registration/application/states.json';
import { stateAbbreviations } from '../registration/application/states_abbr.json';
import VueRouter from '../../../router';
import IpApi from '../../../services/api.ipify.js';


export default {
  components: {
   // AchConfirmationModal: () => import('./AchConfirmationModal'),
    PageHeader: () => import('@/components/app/PageHeader'),
    // RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
  },
  data: () => ({
    regId: null,
    debounceTimeout: 3000,
    appSubmitted: false,
    mask: '(###) ###-####',
    customerInfoForm: false,
    states: stateAbbreviations,
    updatingVehicleDetails: false,
    updatingShippingAddress: false,
    paymentType: null,
    totalAmountDue: 0,
    requestPending: false,
    routingOneViewable: true,
    routingTwoViewable: true,
    accountOneViewable: true,
    accountTwoViewable: true,
    paymentTypeForm: false,
    completedStatus: 'Transaction Completed',
    canceledStatus: 'Canceled',
    ipAddress: null,
    bill: {
      fullName: null,
      phoneNumber: null,
      addressOne: null,
      addressTwo: null,
      city: null,
      state: null,
      zip: null,
      bankName: null,
      routingNumber: null,
      accountNumber: null,
      confirmRoutingNumber: null,
      confirmAccountNumber: null,
      accountType: null,
    fieldTypes: { // add this for change input type
      password: 'text',
    }
    },
    rules: {
      paymentType: [(v) => !!v || 'You must select a payment type'],
  name: [
        v => !!v || 'Name is required',
        v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters',
      ],
      email: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      address: [v => !!v || 'Address 1 is required', v => /^[a-zA-Z0-9\s]*$/.test(v) || 'Only letters, numbers and spaces are allowed'],
      city: [
        v => !!v || 'City is required',
        v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
        v => (v && v.length <= 50) || 'City must be less than 50 characters',
      ],
      state: [
        v => !!v || 'State is required',
        v => (v && v.length == 2) || 'State must be only 2 characters',
        v => /^[A-Z]*$/.test(v) || 'Only uppercase letters are allowed',
      ],
      zip: [v => !!v || 'Zip Code is required', v => /^\d{5}$/.test(v) || 'Zip Code must be 5 numbers only'],
      routingNumber: [v => !!v || 'Routing Number is required', v => /^\d{9}$/.test(v) || 'Routing number should be 9 digits only'],
      accountNumber: [v => !!v || 'Account Number is required', v => /^\d{4,17}$/.test(v) || 'Account number should be atleast 4 digits'],
      confirmRoutingNumber: [v => !!v || 'Routing Number is required', v => /^\d{9}$/.test(v) || 'Routing number should be 9 digits only'], 
      confirmAccountNumber: [v => !!v || 'Account Number is required', v => /^\d{4,17}$/.test(v) || 'Account number should be atleast 4 digits'],
      bankName: [
        v => !!v || 'Bank Name is required',
        v => /^[a-zA-Z][a-zA-Z\s]*$/.test(v) || 'Only letters and spaces are allowed',
      ],
      accountType: [(v) => !!v || 'You must select a account type'],

    },
    payNow: 'Pay Now', // this are case dependent and must match from the api
    wireType: 'ACH or wire transfer', // this are case dependent and must match from the api
    checkType: "Money Order or Cashier's check", // this are case dependent and must match from the api
     payNowInstructions: [
      {
        item: 'ACH Amount',
        value: 'Verify your amount due in your cost breakdown (see cost breakdown page) and submit the form below.',
      },
       {
        item: 'Payment Receipt',
        value: 'In 1-2 days, once the funds have been cleared by your bank, you will get an e-mail receipt.',
      },
    ],
    wireInstructions: [
      {
        item: 'Wire Transfer Amount',
        value: 'Verify your amount due in your cost breakdown (see cost breakdown page)',
      },
      {
        item: 'Recipient Business Name',
        value: 'The Title Girl, Inc.',
      },
      {
        item: 'Recipient Address',
        value: '16775 Addison Road, Suite 400 Addison, Texas 75001',
      },
      {
        item: 'Recipient Phone Number',
        value: '(214) 997-6005',
      },
      {
        item: 'Recipient Routing Number',
        value: '111322994',
      },
      {
        item: 'Recipient Bank Name',
        value: 'Plains Capital Bank',
      },
      {
        item: 'Recipient Account Number',
        value: '7621515601',
      },
      {
        item: 'Reference',
        value: 'Please be sure to include the last 6 of the Vin and your last name to your payment',
      },
    ],
    moneyOrderInstructions: [
      {
        item: 'Step 1',
        value: 'Verify your amount due in your cost breakdown (see cost breakdown page)',
      },
      {
        item: 'Step 2',
        value: "Secure a money order or cashier's check from your bank.",
      },
      {
        item: 'Step 3',
        value:
          "Please make payment payable to The Title Girl. Include the last 6 of your vehicle's vin number and your last name in the reference details of your payment.",
      },
      {
        item: 'Step 4',
        value: "Mail the cashier's check or money order to The Title Girl. ",
      },
      {
        item: 'Step 5',
        value:
          'Include it in your package with all registration documents (using FedEx label provided to you in an email from The Title Girl) or mail to us directly at The Title Girl, 16775 Addison Rd. Ste. 400, Addison, TX 75001',
      },
      {
        item: 'Note',
        value: 'Personal or e-pay checks are not accepted.',
      },
    ],
  }),
  created() {
    this.setData();
  },
  mounted() {},


 watch: {
    paymentType () {
      this.savePaymentInfo();
    },
  },

  methods: {
       showSuccessSnack(msg) {
      this.$store.commit('snackmsg/show', { text: msg });
    },
    handleType(event) {
  const { srcElement, type } = event;
  const { name, value } = srcElement;

  if(type === 'blur' && !value) {
    this.fieldTypes[name] = 'text'
  } else {
    this.fieldTypes[name] = 'password'
  }
},
    setData() {
     console.log('> type: ' + this.$store.state.registration.app.PaymentType);

     if (!this.$store.state.registration.app.PaymentType)
      this.paymentType = this.$store.state.registration.app.PaymentType;
     else this.paymentType = "Pay Now"

      this.totalAmountDue = this.$store.state.registration.app.RegistrationAmount;
      this.fullName = this.$store.state.registration.app.CustomerName;
      this.phoneNumber = this.$store.state.registration.app.PhoneNumber;
      this.addressOne = this.$store.state.registration.app.ShippingAddrLine1;
      this.addressTwo = this.$store.state.registration.app.ShippingAddrLine2;
      this.city = this.$store.state.registration.app.ShippingCity;
      this.state = this.$store.state.registration.app.ShippingState;
      this.zip = this.$store.state.registration.app.ShippingZipCode;
      // console.log('type: ' + this.paymentType);
    },
    savePaymentInfo() {
      if (this.paymentType === null) {
        // console.log('> type === null');
        return;
      } else if (this.paymentType === this.$store.state.registration.app.PaymentType) {
        // console.log('> type === store type');
        return;
      }

      const payload = {
        regId: this.$route.params.regId,
        paymentType: this.paymentType,
      };
      // console.log(payload);
      this.$store
        .dispatch('paymentType/save', payload)
        .then((res) => {
          // console.log('res', res);
          this.$store.commit('snackmsg/show', {
            text: 'Your payment method has been updated',
          });
          // this.$router.push({
          //   name: 'app.dashboard',
          // });
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    submitACHPayment() {
      this.requestPending = true;
      this.appSubmitted = true;
      IpApi.get('', {params: { format: 'json'}}) // '?format=jsonp&callback=getIP'
        .then(res => {
          this.ipAddress = res.data.ip;
        }).catch(err => {
              console.log(err);
              reject(`We were unable to retrieve ip address. ${err}`);
            });

      const payload = {
        regId: this.$route.params.regId,
        paymentType: this.paymentType,
        achOrderID:  this.$route.params.regId  + '-' + this.$store.state.registration.app.lastSixOfVin, 
        bill: this.bill,
        totalAmountDue: this.totalAmountDue,
        ipAddress: this.ipAddress
      };

      this.$store
        .dispatch('ach/submitACH', payload)
        .then(res => {
          this.appSubmitted = true;
          this.requestPending = false;
        //  this.AchConfirmationModal=true;
          this.showSuccessSnack(res.msg);
          // this.$store.commit('snackmsg/show', {
          //   //text: 'Your ACH Payment has been submitted successfully. Reference number is '+ referenceNumber +".",

          // });
          this.$router.push({
           // name: 'app.dashboard',
            name: 'app.registration.achConfirmation',
            params: {message: res.msg  , success: 'true' }
          });
        })
        .catch(err => {
          this.appSubmitted = true;
          this.$store.commit('snackmsg/show', {
            text: err,
            color: 'error',
          });
           this.$router.push({
           // name: 'app.dashboard',
            name: 'app.registration.achConfirmation',
            params: {message: err , success: 'false'}
          });
        });
    },
  },
  filters: {
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  computed: {
     checkRoutingNumberMatch() {
      return this.bill.routingNumber === this.bill.confirmRoutingNumber || 'Routing number must match';
    },
     checkAccountNumberMatch() {
      return this.bill.accountNumber === this.bill.confirmAccountNumber || 'Account number must match';
    },
     confirmRoutingNumberRequired() {
      return !!this.bill.confirmRoutingNumber || 'Confirm Routing Number required';
    },
     confirmAccountNumberRequired() {
      return !!this.bill.confirmAccountNumber || 'Confirm Account Number required';
    },
    ymm: function () {
      return this.$store.state.registration.app.yearMakeModel;
    },
    vinAndLastName: function () {
      return this.$store.state.registration.app.lastSixOfVin + '-' + this.$store.state.registration.app.CustomerName;
    },
    achOrderID: function () {
      return  this.$route.params.regId  + '-' + this.$store.state.registration.app.lastSixOfVin;
    },
    instructionsToShow: {
      get: function () {
        // console.log(this.paymentType);
        let value = -1;
           if (this.paymentType === this.payNow) {
          value = 0;
        }
         if (this.paymentType === this.wireType) {
          value = 1;
        }
         if (this.paymentType === this.checkType) {
          value = 2;
        }
        return value;
      },
      set: function (value) {
        return value;
      },
    },
  },
  destroyed() {},
};
</script>

<style scoped>
.table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-collapse: collapse;
}
th,
td {
  padding: 15px;
}
.large-text >>> label {
  font-size: 25px;
}
</style>
